export const API_URL = import.meta.env.VITE_API_URL as string;
export const GITHUB_OAUTH_CLIENT_ID = import.meta.env.VITE_GITHUB_OAUTH_CLIENT_ID as string;
export const SMARTLOOK_PROJECT_KEY = import.meta.env.VITE_SMARTLOOK_PROJECT_KEY as string;
export const IS_LOCAL = import.meta.env.VITE_IS_LOCAL as boolean;
export const ENVIRONMENT = (import.meta.env.VITE_ENVIRONMENT as string) ?? 'local';
export const VITE_SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string;
export const JWT_SECRET = '123456' as string;
export const GRADIO_PAGE_URL = import.meta.env.GRADIO_PAGE_URL as string;
export const PLAN_ID = import.meta.env.VITE_PLAN_ID as string;
export const BUILD_PLAN_ID = import.meta.env.VITE_BUILD_PLAN_ID as string;
export const RAZORPAY_KEY = import.meta.env.VITE_RAZORPAY_KEY as string;
export const TEST_PLAN_ID = import.meta.env.VITE_TEST_PLAN_ID as string;
