import { useUserCredits } from '@/hooks/useUserCredits';
import { useTeamCreditsStore } from '@/stores/teamCredits';
import { useEffect } from 'react';

export const StreamDataProvider = () => {
  const { userCredits } = useUserCredits(true);
  const { setCredits } = useTeamCreditsStore();

  useEffect(() => {
    setCredits(userCredits);
  }, [userCredits]);

  return <></>;
};
