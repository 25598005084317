import Axios, { AxiosRequestConfig } from 'axios';

import { API_URL } from '@/config';
import { useNotificationStore } from '@/stores/notifications';
import storage from '@/utils/storage';
import { useErrorStore } from '@/stores/showError';

function authRequestInterceptor(config: AxiosRequestConfig) {
  // console.log('[AXIOS] Request:', config.url);

  const token = storage.getToken();
  if (token) {
    config.headers.authorization = 'Bearer ' + `${token}`;
    const teamId = storage.getTeam()?._id;
    if (teamId && config.url?.includes(API_URL)) {
      config.headers['X-Team'] = teamId;
    }
  }
  config.headers.Accept = 'application/json';
  // config.headers['Access-Control-Allow-Origin'] = '*';
  return config;
}

export const axios = Axios.create({
  baseURL: API_URL,
});

// axios.defaults.timeout = 10000; // 10 seconds
axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    // console.log('[AXIOS] Response:', response?.config?.url, response);
    const clearError = useErrorStore.getState().clearError;

    // Clear any previous errors on successful response
    // clearError();

    return response.data;
  },
  (error) => {
   // console.error('[AXIOS] Error:', error);
    const status = error?.response?.status;
    const setError = useErrorStore.getState().setError;

    if (status === 401) {
      storage.clearToken();
      window.location.assign(window.location.origin as unknown as string);
    } else if (status === 403) {
      window.location.href = '/not-authorized';
    } 
    // else if (status === 402) {
    //   window.location.href = '/app/repositories?settings';
    // }
    // else if (status === 402) {
    //     window.location.href = '/plan-ended';
    // }
    else {
      // General error handling
      let message = error.message;
      let title = 'Error';

      const errorDetails = error?.response?.data;
      const errorDetailsArray = error?.response?.data?.detail;
      const errorDetailData = error?.response?.data?.data;

      if (message === 'Network Error') {
        // If the error is a network error, return the rejected promise without setting the error
        return Promise.reject(error);
      }
      if (errorDetails?.message === 'Not Found') {
        // If the error is a "Not Found", return the rejected promise without setting the error
        return Promise.reject(error);
      }

      // Check if the error is the specific message "Active clusters not found"
      if (errorDetails?.data === 'Active clusters not found') {
        // Return the rejected promise but don't trigger the error modal
        return Promise.reject(error);
      }

      if (errorDetails || errorDetailsArray) {
        if (Array.isArray(errorDetailsArray)) {
          // If it's an array, map through to get messages
          message = errorDetailsArray?.map((detail: any) => detail?.msg).join(', ');
        } else if (errorDetails?.detail) {
          // If 'detail' exists, use it as the message
          message = errorDetails?.detail;
        } else if (errorDetails?.data) {
          // If 'data' exists, use it as the message
          message = errorDetails?.data;
        } else if (errorDetails?.msg) {
          // If 'msg' exists, use it as the message
          message = errorDetails?.msg;
        } else if (errorDetailData) {
          message = errorDetailData;
        } else if (errorDetails?.error) {
          message = errorDetails?.error;
        } else if (errorDetails?.errorDetails) {
          message = errorDetails?.message;
        } else if (errorDetails?.status && !errorDetails?.data) {
          message = errorDetails?.status === 'failed' ? 'Request failed' : errorDetails?.status;
        }
      }

      setError(title, message);

      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);
