import { Outlet, useRoutes } from 'react-router-dom';

import { Landing, NotAuthorizedPage, NotFoundPage, PlanEndedPage } from '@/features/misc';
import { useAuth } from '@/lib/auth';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import { AppContextProvider } from '@/context/AppContext';
import { Navigate } from 'react-router-dom';
import { Suspense } from 'react';
import { Spinner } from '@primer/react';
import { lazyImport } from '@/utils/lazyImport';
import { MainLayout } from '@/components/Layout';
import { GradioLayout } from '@/features/deployments/components/GradioLayout';
import { WorkspaceLayout } from '@/components/Layout/WorkspaceLayout';
import { PricingLayout } from '@/components/Layout';
// import { DeploymentDependenciesModal } from '@/features/deployments';

// const { Repository } = lazyImport(() => import('@/features/repositories'), 'Repository');
const { Gradio } = lazyImport(() => import('@/features/deployments'), 'Gradio');
// const { Waitlist } = lazyImport(() => import('@/features/misc'), 'Waitlist');
// const { Workspace } = lazyImport(() => import('@/features/workspaces'), 'Workspace');
const { Pricing } = lazyImport(() => import('@/features/pricing'), 'Pricing');
const { App } = lazyImport(() => import('@/features/app'), 'App');

const { AuthRoutes } = lazyImport(() => import('@/features/auth'), 'AuthRoutes');

export const AppRoutes = () => {
  const auth = useAuth();

  // const RepositoryPage = () => {
  //   return (
  //     <AppContextProvider>
  //       <MainLayout>
  //         <Repository>
  //           <Suspense
  //             fallback={
  //               <div className="h-full w-full flex items-center justify-center">
  //                 <Spinner size="large" />
  //               </div>
  //             }
  //           >
  //             <Outlet />
  //           </Suspense>
  //         </Repository>
  //       </MainLayout>
  //     </AppContextProvider>
  //   );
  // };

  const GradioPage = () => {
    return (
      <AppContextProvider>
        <GradioLayout>
          <Gradio>
            <Suspense
              fallback={
                <div className="h-full w-full flex items-center justify-center">
                  <Spinner size="large" />
                </div>
              }
            >
              <Outlet />
            </Suspense>
          </Gradio>
        </GradioLayout>
      </AppContextProvider>
    );
  };

  // const WaitlistPage = () => {
  //   return (
  //     <AppContextProvider>
  //       <Waitlist>
  //         <Suspense
  //           fallback={
  //             <div className="h-full w-full flex items-center justify-center">
  //               <Spinner size="large" />
  //             </div>
  //           }
  //         >
  //           <Outlet />
  //         </Suspense>
  //       </Waitlist>
  //     </AppContextProvider>
  //   );
  // };

  // const WorkspacePage = () => {
  //   return (
  //     <AppContextProvider>
  //       <WorkspaceLayout>
  //         <Workspace>
  //           <Suspense
  //             fallback={
  //               <div className="h-full w-full flex items-center justify-center">
  //                 <Spinner size="large" />
  //               </div>
  //             }
  //           >
  //             <Outlet />
  //           </Suspense>
  //         </Workspace>
  //       </WorkspaceLayout>
  //     </AppContextProvider>
  //   );
  // };

  const PricingPage = () => {
    return (
      <PricingLayout>
        <Pricing>
          <Suspense
            fallback={
              <div className="h-full w-full flex items-center justify-center">
                <Spinner size="large" />
              </div>
            }
          >
            <Outlet />
          </Suspense>
        </Pricing>
      </PricingLayout>
    );
  };

  const NotFound = () => {
    return (
      <NotFoundPage>
        <Suspense
          fallback={
            <div className="h-full w-full flex items-center justify-center">
              <Spinner size="large" />
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </NotFoundPage>
    );
  };

  const AppPage = () => {
    return (
      <AppContextProvider>
        <MainLayout>
          <App>
            <Suspense
              fallback={
                <div className="h-full w-full flex items-center justify-center">
                  <Spinner size="large" />
                </div>
              }
            >
              <Outlet />
            </Suspense>
          </App>
        </MainLayout>
      </AppContextProvider>
    );
  };

  const commonRoutes = [
    {
      path: '/',
      element: (
        <AppContextProvider>
          <Landing />
        </AppContextProvider>
      ),
    },
    {
      path: '/auth/*',
      element: <AuthRoutes />,
    },
    // { path: 'app/repositories/:projectId', element: <RepositoryPage /> },
    { path: 'gradio/:gradioId/:clusterId/:sessionId/:workflow', element: <GradioPage /> },
    // { path: 'comfyui/:name', element: <WorkspacePage /> },
    // { path: 'waitlist', element: <WaitlistPage /> },
    // { path: 'pricing', element: <PricingPage /> },
    { path: 'app/repositories/:projectId', element: <AppPage /> },
    { path: 'not-authorized', element: <NotAuthorizedPage /> },
    // { path: 'plan-ended', element: <PlanEndedPage /> },
    { path: 'not-found', element: <NotFound /> },
    { path: '*', element: <NotFound /> },
  ];

  // Always use protectedRoutes for development purposes
  // if (auth.user) {
  //   return <Navigate to="/" />;
  // }

  // const routes = auth.user ? protectedRoutes : publicRoutes;

  const routes = auth.user ? protectedRoutes : commonRoutes;

  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
