import React, { ReactNode, useEffect, useState } from 'react';
import LogoWithFullName from '@/assets/logo-with-fullname.svg';
import { useGetQueueData } from '../api/getGradioQueue';
import { Box } from '@primer/react';
import { DotFillIcon } from '@primer/octicons-react';
import { Spinner } from 'phosphor-react';
import { useParams } from 'react-router-dom';
import { useSSE } from '@/lib/useSSE';
import { Head } from '@/components/Head';

interface GradioLayoutProps {
  children: ReactNode;
}

export const GradioLayout: React.FC<GradioLayoutProps> = ({ children }) => {
  const [isGenerationStatusRunning, setIsGenerationStatusRunning] = useState(false);

  const { clusterId, sessionId, workflow } = useParams<{
    clusterId: string;
    sessionId: string;
    workflow: any;
  }>();

  // const apiDocsUrl = window.location.search.substring(1);

  const { data: generationData } = useSSE<any>(
    `/cluster/${clusterId}/status/stream/public?session_id=${sessionId}`,
    {},
    !!clusterId && !!sessionId,
    false,
    true,
    'json',
    'v2'
  );

  const containerData = generationData?.containers || [];
  const clusterMode = generationData?.mode;

  // const { data, isLoading } = useGetQueueData(`https://${apiDocsUrl}/api/v1/comfy`, {
  //   enabled: isGenerationStatusRunning,
  // });

  useEffect(() => {
    if (differingContainer?.generation_status === 'active' && clusterMode === 'api') {
      setIsGenerationStatusRunning(true);
    } else {
      setIsGenerationStatusRunning(false);
    }
  }, [generationData]);

  // const queue_pending = data?.data?.queue_pending?.length || 0;
  // const queue_running = data?.data?.queue_running?.length || 0;

  const handleLogoClick = () => {
    window.location.href = 'https://hub.flowscale.ai';
  };

  let differingContainer: any = null;

  // Check every container and find the one whose gpu_status and generation_status are different from the rest
  if (containerData.length > 1) {
    for (let i = 0; i < containerData.length; i++) {
      const currentContainer = containerData[i];
      let isDifferent = false;

      for (let j = 0; j < containerData.length; j++) {
        if (i !== j) {
          const comparisonContainer = containerData[j];
          if (
            currentContainer.gpu_status !== comparisonContainer.gpu_status ||
            currentContainer.generation_status !== comparisonContainer.generation_status
          ) {
            isDifferent = true;
            break;
          }
        }
      }

      if (isDifferent) {
        differingContainer = currentContainer;
        break;
      }
    }
  } else if (containerData.length === 1) {
    differingContainer = containerData[0]; // Use the only container available
  }

  const countContainersByStatus = (status: string) => {
    return containerData.filter((container: any) => container.gpu_status === status).length;
  };

  return (
    <Box className="flex flex-col h-screen overflow-hidden bg-[#16191D]">
      <Head title={workflow} />
      <div className="relative z-[1] px-[120px] flex-shrink-0 flex h-16 bg-[#16191D] border-b border-[#343B45]">
        <div className="flex items-center">
          <img
            src={LogoWithFullName}
            onClick={handleLogoClick}
            className="h-6 cursor-pointer"
            alt="logo"
          />
        </div>

        <div className="flex-1 flex justify-end">
          <div className="flex gap-4 items-center">
            <div className="flex">
              <Box
                className="rounded-xl text-[#adbac7] gap-3 flex justify-center items-center px-3 py-1"
                sx={{
                  backgroundColor:
                    'var(--bgColor-neutral-muted, var(--color-neutral-subtle, rgba(99,110,123,0.1)))',
                }}
              >
                <p>GPU Status:</p>
                <p className="text-sm capitalize">
                  {countContainersByStatus('running') > 0 && (
                    <>
                      <DotFillIcon fill="#14C078" /> {countContainersByStatus('running')} running
                    </>
                  )}
                  {countContainersByStatus('initializing') > 0 && (
                    <>
                      <DotFillIcon fill="#bdbdbd" /> {countContainersByStatus('initializing')}{' '}
                      initializing
                    </>
                  )}
                  {countContainersByStatus('cold-starting') > 0 && (
                    <>
                      <DotFillIcon fill="#187DDB" /> {countContainersByStatus('cold-starting')}{' '}
                      cold-starting
                    </>
                  )}
                  {countContainersByStatus('idle') > 0 && (
                    <>
                      <DotFillIcon fill="#c3c601" /> {countContainersByStatus('idle')} idle
                    </>
                  )}
                  {countContainersByStatus('stopped') > 0 && (
                    <>
                      <DotFillIcon fill="#de0202" /> {countContainersByStatus('stopped')} stopped
                    </>
                  )}
                </p>
              </Box>
            </div>
            {/* <div className="flex">
              <Box
                className="rounded-xl text-[#adbac7] gap-3 flex justify-center items-center px-3 py-1"
                sx={{
                  backgroundColor:
                    'var(--bgColor-neutral-muted, var(--color-neutral-subtle, rgba(99,110,123,0.1)))',
                }}
              >
                <DotFillIcon fill={queue_running === 0 ? '#f1f500' : '#14C078'} />
                <p>Running:</p>

                {isLoading ? (
                  <Spinner size={20} className="animate-spin" />
                ) : queue_running === 0 ? (
                  <p className="text-sm">No running jobs</p>
                ) : (
                  <ul>
                    <li>{queue_running}</li>
                  </ul>
                )}
              </Box>
            </div> */}
            {/* <div>
              <Box
                className="rounded-xl text-[#adbac7] gap-3 flex justify-center items-center px-3 py-1"
                sx={{
                  backgroundColor:
                    'var(--bgColor-neutral-muted, var(--color-neutral-subtle, rgba(99,110,123,0.1)))',
                }}
              >
                <DotFillIcon fill={queue_pending === 0 ? '#f1f500' : '#14C078'} />
                <p>Pending:</p>
                {isLoading ? (
                  <Spinner size={20} className="animate-spin" />
                ) : queue_pending === 0 ? (
                  <p className="text-sm">No pending jobs</p>
                ) : (
                  <ul>
                    <li>{queue_pending}</li>
                  </ul>
                )}
              </Box>
            </div> */}
          </div>
        </div>
      </div>
      <main className="flex-1 relative overflow-y-auto focus:outline-none">{children}</main>
    </Box>
  );
};
