import React from 'react';

type CustomBlankslateProps = {
  heading: string;
  description: string;
  icon: JSX.Element;
};

const CustomBlankslate = ({ heading, description, icon }: CustomBlankslateProps) => (
    <div className="flex flex-col gap-1 justify-center items-center">
      <div className="custom-blankslate__visual">{icon}</div>
      <h2 className="custom-blankslate__heading">{heading}</h2>
      <p className="text-[#57606a]">{description}</p>
    </div>
);

export default CustomBlankslate;