import React from 'react';
import { SignOut, UserCircle, SignIn, Tag, CreditCard } from 'phosphor-react';
import { useAuth } from '@/lib/auth';
import { ActionMenu, ActionList, Box } from '@primer/react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '@/context/AppContext';

type UserNavigationItem = {
  name: string;
  to: string;
  onClick?: () => void;
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

export const UserNavigation = ({
  userDetails,
  menuClassName = '',
}: {
  userDetails: any;
  menuClassName?: string;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const triggerRef = React.useRef<HTMLButtonElement>(null);

  const showLogoWithFullName =
    location.pathname.includes('/app/repositories') &&
    !location.pathname.includes('/app/repositories/');
  const { logout } = useAuth();
  const { setActiveTabModule } = useAppContext();
  const navigate = useNavigate();

  const userNavigation = [
    {
      icon: CreditCard,
      name: 'Billing & Plans',
      // to: '/pricing',
      // onClick: () => window.open('https://flowscale.ai/pricing', '_blank'),
      onClick: () => navigate('/app/repositories?settings', { state: { tab: 'Billing & Plans' } }),
    },
    {
      icon: UserCircle,
      name: 'Your Profile',
      to: showLogoWithFullName ? '/app/profile' : '',
      onClick: () => setActiveTabModule('profile'),
    },
    {
      icon: SignOut,
      name: 'Sign out',
      to: '',
      onClick: () => {
        logout();
      },
    },
  ].filter(Boolean) as UserNavigationItem[];

  if (!userDetails) {
    userNavigation.unshift({
      icon: SignIn,
      name: 'Log in',
      to: '/',
    });
    userNavigation.splice(userNavigation.length - 2, 2);
  }

  return (
    <Box className="relative">
      <ActionMenu onOpenChange={setIsOpen} open={isOpen}>
        <ActionMenu.Anchor>
          <button
            ref={triggerRef}
            onClick={() => setIsOpen((prev) => !prev)}
            className={`flex items-center gap-2`}
            aria-label="Open menu"
          >
            {userDetails?.avatar_url ? (
              <img className="h-8 w-8 rounded-full" src={userDetails?.avatar_url} alt="" />
            ) : (
              <UserCircle size={34} weight="fill" />
            )}
          </button>
        </ActionMenu.Anchor>
        <ActionMenu.Overlay ignoreClickRefs={[triggerRef]}>
          <ActionList
            sx={{
              padding: '0px 0px',
            }}
          >
            <Box className={`flex items-center gap-2 py-3 px-4 text-sm border-b border-gray-600`}>
              @{userDetails?.login}
            </Box>
            {userNavigation.map((item) => (
              <Box
                key={item.name}
                onClick={() => {
                  if (item.onClick) {
                    item.onClick();
                  }
                  if (item.to) {
                    navigate(item.to);
                  }
                  setIsOpen(false);
                }}
                className={`flex rounded-md items-center gap-2 py-3 px-4 hover:bg-gray-700 hover:text-white cursor-pointer text-xs`}
              >
                {item.icon && <item.icon className="h-4 w-4" aria-hidden="true" />}
                {item.name}
              </Box>
            ))}
          </ActionList>
        </ActionMenu.Overlay>
      </ActionMenu>
    </Box>
  );
};
